<template>
<div class="honor">
    <div class="p1"><img class="floatimg" src="../assets/direct/honor1.png" alt=""></div>
    <div class="p2"><img class="floatimg" src="../assets/direct/honor2.png" alt=""></div>
    <div class="p3"><img class="floatimg" src="../assets/direct/honor1.png" alt=""></div>
    <div class="p4"><img class="floatimg" src="../assets/direct/honor2.png" alt=""></div>
    <div class="p5"><img class="floatimg" src="../assets/direct/honor1.png" alt=""></div>
    <div class="p6"><img class="floatimg" src="../assets/direct/honor2.png" alt=""></div>
    <div class="p7"><img class="floatimg" src="../assets/direct/honor1.png" alt=""></div>
    <div class="p8"><img class="floatimg" src="../assets/direct/honor1.png" alt=""></div>
  <div class="TOPtitle">
    <br>
    <br>
    <br>
    <h1>链时代近年荣誉墙<img src="../assets/direct/p8.svg" alt=""></h1>
  </div>
  <div class="block1">
    <el-timeline >
      <el-timeline-item timestamp="2020" placement="top"  size="large" icon="el-icon-medal" type="info">
        <el-card class="wow animate__fadeInBottomRight" data-wow-duration="1s" data-wow-delay="0s">
          <ul>
              <li>全国大学生信息安全竞赛国家二等奖</li>
              <li>中国大学生计算机设计大赛国家三等奖</li>
              <li>蓝桥杯全国软件和信息技术专业人才大赛四川省二等奖</li>
              <li>中国高校计算机大赛微信小程序应用开发赛西南赛区三等奖</li>
              <li>四川省计算机作品赛四川省三等奖</li>
          </ul>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="2021" placement="top" size="large" icon="el-icon-medal"  type="info">
        <el-card class="wow animate__fadeInBottomRight" data-wow-duration="1s" data-wow-delay="0s">
          <ul>
              <li>中国大学生计算机设计大赛国家二等奖</li>
              <li>中国大学生计算机设计大赛四川省一等奖</li>
              <li>中国国际“互联网+”创新创业大赛四川省一等奖</li>
              <li>蓝桥杯全国软件和信息技术专业人才大赛四川省二等奖</li>
              <li>全国大学生电子商务“创新、创意及创业”挑战赛四川省三等奖</li>
          </ul>
        </el-card>
      </el-timeline-item>
      <el-timeline-item  timestamp="2022" placement="top" size="large" icon="el-icon-medal" type="info">
        <el-card class="wow animate__fadeInBottomRight" data-wow-duration="1s" data-wow-delay="0s">
          <ul>
              <li>计算机软件著作权×2</li>
              <li>大学生创新创业训练计划项目国家级立项</li>
              <li>中国高校计算机大赛微信小程序应用开发赛国家三等奖</li>
              <li>中国高校计算机大赛网络技术挑战赛国家三等奖</li>
              <li>中国大学生服务外包创新创业大赛国家三等奖</li>
              <li>“挑战杯”大学生创业计划竞赛四川省金奖</li>
              <li>四川省计算机作品赛特等奖</li>
              <li>中国大学生服务外包创新创业大赛西南赛区一等奖</li>
              <li>中国高校计算机大赛微信小程序应用开发赛西南赛区一等奖</li>
              <li>中国大学生计算机设计大赛四川省一等奖</li>
          </ul>
        </el-card>
      </el-timeline-item>
       <el-timeline-item  timestamp="2023" placement="top" size="large" icon="el-icon-medal" type="info">
        <el-card class="wow animate__fadeInBottomRight" data-wow-duration="1s" data-wow-delay="0s">
          <ul>
              <li>计算机作品赛特等奖</li>
              <li>中国大学生计算机设计大赛国家二等奖×2</li>
              <li>中国大学生计算机设计大赛四川省一等奖×3</li>
              <li>中国大学生计算机设计大赛四川省二等奖</li>
              <li>全国大学生电子商务“创新、创意及创业”挑战赛四川省一等奖</li>
              <li>第十四届中国大学生服务外包创新创业大赛国家三等奖×1</li>
              <li>第十四届中国大学生服务外包创新创业大赛四川省一等奖</li>
              <li>第十四届中国大学生服务外包创新创业大赛四川省三等奖×3</li>
              <li>中国高校计算机大赛—网络技术挑战赛四川省二等奖×2</li>
              <li>中国高校计算机大赛—网络技术挑战赛四川省三等奖×1</li>
              <li>全国大学生信息安全竞赛国家一等奖</li>
              <li>中国国际“互联网+”队伍：10</li>
              <li>大学生创新创业大赛项目：5</li>
              <li>peerteaching×2</li>
          </ul>
        </el-card>
      </el-timeline-item>
      <el-timeline-item  timestamp="2024" placement="top" size="large" icon="el-icon-medal" type="info">
        <el-card class="wow animate__fadeInBottomRight" data-wow-duration="1s" data-wow-delay="0s">
            ...
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
  <div class="imgcon" id="imgconPC">
    <div class="title"><div></div>照片墙<p>(部分展示)</p></div>
    <el-row :gutter="20" class="wow animate__lightSpeedInRight" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/大创省金-高豪.jpg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/获奖证书 SCJSJ202301030074-4.png" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/计设省级特等奖-高豪.jpg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/计设省级特等奖-陈津旭.jpg" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInLeft" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/信安国一-王柯阳.jpg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/计设省一-罗家逸.png" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/数学竞赛省二-王柯阳.jpg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/2023060474.png" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInRight" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/计设省三-高豪.jpg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/计设省二-周泽林庆.png" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/服创省三-陈昊.png" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"><img src="../assets/honor/服创省三-周泽林庆.png" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInLeft" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="6"><div class="grid-content2 bg-purple"><img src="../assets/honor/专利-陈昊.png" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content2 bg-purple"><img src="../assets/honor/优秀指导教师-李美蓉.jpg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content2 bg-purple"><img src="../assets/honor/微信小程序大赛国三-高豪.jpg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content2 bg-purple"><img src="../assets/honor/微信小程序大赛省一-高豪.jpg" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInRight" data-wow-duration="1s" data-wow-delay="0s" >
      <el-col :span="6"><div class="grid-content2 bg-purple"><img src="../assets/honor/网挑国三-王杰.jpg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content2 bg-purple"><img src="../assets/honor/网挑省二-陈昊.jpeg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content2 bg-purple"><img src="../assets/honor/蓝桥杯省2-郭翔.jpg" alt=""></div></el-col>
      <el-col :span="6"><div class="grid-content2 bg-purple"><img src="../assets/honor/蓝桥杯省2-陈毅婷.jpg" alt=""></div></el-col>
    </el-row>
    </div>

    <div class="imgcon" id="imgconMobile" style="display: none;">
    <div class="title"><div></div>照片墙<p>(部分展示)</p></div>
    <el-row :gutter="20" class="wow animate__lightSpeedInRight" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/大创省金-高豪.jpg" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/获奖证书 SCJSJ202301030074-4.png" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInLeft" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/信安国一-王柯阳.jpg" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/计设省级特等奖-陈津旭.jpg" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInRight" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/三创省三-高豪.jpg" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/计设省一-罗家逸.png" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInLeft" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/数学竞赛省二-王柯阳.jpg" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/2023060474.png" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInRight" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/计设省三-高豪.jpg" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/计设省二-周泽林庆.png" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInleft" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/服创省三-陈昊.png" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content bg-purple"><img src="../assets/honor/服创省三-周泽林庆.png" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInRight" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="12"><div class="grid-content2 bg-purple"><img src="../assets/honor/专利-陈昊.png" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content2 bg-purple"><img src="../assets/honor/优秀指导教师-李美蓉.jpg" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInLeft" data-wow-duration="1s" data-wow-delay="0s">
      <el-col :span="12"><div class="grid-content2 bg-purple"><img src="../assets/honor/微信小程序大赛国三-高豪.jpg" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content2 bg-purple"><img src="../assets/honor/微信小程序大赛省一-高豪.jpg" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInRight" data-wow-duration="1s" data-wow-delay="0s" >
      <el-col :span="12"><div class="grid-content2 bg-purple"><img src="../assets/honor/网挑国三-王杰.jpg" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content2 bg-purple"><img src="../assets/honor/网挑省二-陈昊.jpeg" alt=""></div></el-col>
    </el-row>
    <el-row :gutter="20" class="wow animate__lightSpeedInLeft" data-wow-duration="1s" data-wow-delay="0s" >
      <el-col :span="12"><div class="grid-content2 bg-purple"><img src="../assets/honor/蓝桥杯省2-郭翔.jpg" alt=""></div></el-col>
      <el-col :span="12"><div class="grid-content2 bg-purple"><img src="../assets/honor/蓝桥杯省2-陈毅婷.jpg" alt=""></div></el-col>
    </el-row>
    <p class="end wow animate__fadeInUp" data-wow-duration="3s" data-wow-delay="0s">期待你的加入</p>
  </div>
</div>

</template>

<script>
export default {
   mounted() {
    new this.$wow.WOW().init()
  },
};
</script>

<style scoped>
  li{
    font-size:22px;
    margin: 10px;
    font-family: Georgia, "Cambria", serif;
  }
  .block1{
    margin:50px 200px 0 200px ;
  }
  
  .el-card{
    box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, .5), 7px 7px 20px 0px rgba(0, 0, 0, .1), 4px 4px 5px 0px rgba(0, 0, 0, .1);
  }
  .TOPtitle {

    position: relative;
    left: calc(50% - 180px)
  }
  .TOPtitle  h1{
      line-height: 60px;
      font-size: 30px;
      margin: 0 0 30px 30px;      
    }
  .TOPtitle img{
    width: 80px;
    height: 80%;
    float: left;
    margin-bottom:200px;
  }
  .imgcon{
    margin:50px 200px 0 200px 
  }
  .imgcon img{
      width: 100%;
      height: 100%;
  }
  .imgcon .title{
   font-size: 30px;
   text-align: center;
   margin-bottom: 30px;
   font-weight:bold;
  }
  .el-row{
    margin-bottom: 20px;
  }  
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .grid-content2 {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .honor:not(.el-card){
    width:100%;
    height: 100%;
    background:  linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);;
    background-size: 500% 500%;
    animation:gradientBG 5s ease infinite;
  }
  @keyframes gradientBG{
	    0% {
	    background-position: 0% 50%;
	    }
	    50% {
	        background-position: 100% 50%;
	    }
	    100% {
	        background-position: 0% 50%;
	    }
	}
  .p1,.p2,.p3,.p4,.p5,.p6,.p7,.p8{
  width: 200px;
    height: 200px;
    position: absolute;
    animation-name: move1;
    animation-timing-function: ease-in-out;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    float: right;
  }
  .floatimg{
      width: 100%;
    }
.p1{
  right: 50px;
    top: 400px;
}
.p2{
  left: 50px;
    top: 600px;
}
.p3{
  left: 240px;
    top: 1400px;
}
.p4{
  right: 440px;
    top: 1600px;
}
.p5{
  right: 1420px;
    top: 1900px;
}
.p6{
  right: 780px;
    top: 100px;
}
.p7{
  right: 840px;
    top: 1000px;
}
.p8{
  right: 40px;
    top: 1000px;
}
@keyframes move1 {
    0% {
        transform: translateY(0px);
        opacity: 0;
    }

    33% {
        opacity: 1;
    }

    66% {
        opacity: 1;
    }

    100% {
        transform: translateY(300px);
        opacity: 0;
    }
}
.end{
  font-size: 30px;
  text-align: center;
  margin-top: 30px;
  padding-bottom: 30px;
  font-weight: bold;
}
@media screen and (max-width: 1300px){
    .block1{
      margin:0;
    }
    #imgconPC{
      margin:0 30px 0 30px !important;
      display: none;
    }
    #imgconMobile{
      margin:0 30px 0 30px !important;
      display:block !important;
    }
  }
</style>
import axios from "axios";


const httpsub = axios.create({
  baseURL: 'https://47.109.153.240:443',
  timeout: 10000,
});




// 添加请求拦截器
httpsub.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers['Content-Type'] = 'multipart/form-data';
  console.log(config.headers);
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
httpsub.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default httpsub

